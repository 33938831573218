import { StepDefinition, IStepDefinitionOptions, IOfflineStepDefinition, IOnlineStepDefinition, IStepTruncateIndexedDB, type ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { DataObjectProgress, type IDataObjectProgressOptions, type IDataObjectProgressJSON } from 'o365.pwa.modules.client.steps.DataObjectProgress.ts';
import { getDataObjectById, type DataObject } from 'o365-dataobject';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { app, userSession } from 'o365-modules';
import { type SyncType, type TruncateIndexDBObjectStoreMode } from "o365.pwa.types.ts";
import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import 'o365.dataObject.extension.Offline.ts';

export enum FileTableType {
    Blob = 'BLOB',
    Binary = 'BINARY'
};

interface IOnBeforeSyncResponse {
    error: Error,
    title: string,
    body: string;
}

interface IOnAfterSyncResponse {
    error: Error,
    title: string,
    body: string;
}

type OnBeforeSync = (dataObject: DataObject, meomory: Object, requestOptions: any) => Promise<IOnBeforeSyncResponse | void>;
type OnAfterSync = (dataObject: DataObject, meomory: Object, records: Array<any>) => Promise<IOnAfterSyncResponse | void>;

export interface IDataObjectStepDefinitionOptions extends IStepDefinitionOptions {
    dataObjectId: string;
    truncateIndexDBObjectStoreMode?: TruncateIndexDBObjectStoreMode;
    onBeforeSync?: OnBeforeSync;
    onAfterSync?: OnAfterSync;
    useOfflineTable?: boolean;
    fileTableType?: FileTableType;
    maxRecords?: number;
    rowCountTimeout?: number;
    failOnNoRecords?: boolean;
}

export class DataObjectStepDefinition extends StepDefinition implements IOfflineStepDefinition<DataObjectProgress>, IOnlineStepDefinition<DataObjectProgress>, IStepTruncateIndexedDB<DataObjectProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    public readonly IOnlineStepDefinition = 'IOnlineStepDefinition';
    public readonly IStepTruncateIndexedDB = 'IStepTruncateIndexedDB';

    public readonly dataObjectId: string;
    public readonly onBeforeSync?: OnBeforeSync;
    public readonly onAfterSync?: OnAfterSync;
    public readonly truncateMode?: TruncateIndexDBObjectStoreMode;
    public readonly fileTableType?: FileTableType;
    public readonly rowCountTimeout?: number;
    public readonly failOnNoRecords?: boolean;

    private get isFileTable(): Boolean {
        return this.fileTableType !== undefined && this.fileTableType !== null;
    }

    private get generateOfflineDataProcName(): string {
        return this.isFileTable ? 'sstp_System_GenerateOfflineDataFiles' : 'sstp_System_GenerateOfflineData';
    }

    private get mySystemOfflineDataViewName(): string {
        return this.isFileTable ? 'sviw_System_MyOfflineDataFiles' : 'sviw_System_MyOfflineData';
    }

    private get mySystemOfflineDataFields(): Array<{name: string}> {
        let fields = [
            { name: 'PrimKey' },
            { name: 'Created' },
            { name: 'CreatedBy_ID' },
            { name: 'Updated' },
            { name: 'UpdatedBy_ID' },
            { name: 'JsonData' },
            { name: 'Type' },
            { name: 'Owner_ID' },
            { name: 'LastCheckIn' },
            { name: 'AppID' },
            { name: 'JsonDataVersion' },
            { name: 'ExternalRef' }
        ];

        if (this.isFileTable) {
            fields = fields.concat([
                { name: 'FileName' },
                { name: 'FileSize' },
                { name: 'FileUpdated' },
                { name: 'FileRef' },
                { name: 'Extension' }
            ]);
        }

        return fields;
    }

    constructor(options: IDataObjectStepDefinitionOptions) {
        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'DataObjectProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.DataObjectProgress.vue');
            }
        });
        
        this.dataObjectId = options.dataObjectId;
        this.truncateMode = options.truncateIndexDBObjectStoreMode;
        options.onBeforeSync ?? (this.onBeforeSync = options.onBeforeSync);
        options.onAfterSync ?? (this.onAfterSync = options.onAfterSync);
        this.fileTableType = options.fileTableType;
        this.rowCountTimeout = options.rowCountTimeout;
        this.failOnNoRecords = options.failOnNoRecords;
    }

    generateStepProgress(options?: IDataObjectProgressOptions | IDataObjectProgressJSON, syncType?: SyncType): DataObjectProgress {
        const progressOptions = <IDataObjectProgressOptions>{
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback,
        }

        return new DataObjectProgress(progressOptions);
    }

    async syncOffline(options: ISyncOptions<DataObjectProgress>): Promise<void> {
        try {
            // const userSession = getUserSession();

            // if (userSession?.personId !== 64800) {
            //     return;
            // }

            // TODO: Switch onBeforeSyncResponse and onAfterSyncResponse to interface or class to contain both online and offline settings
            // TODO: Switch paramaters to interface or class to contain both online and offline settings

            const dataObject: DataObject = getDataObjectById(this.dataObjectId, app.id);
            
            dataObject.enableOffline();

            dataObject.recordSource.expandView = true;

            const appId = app.id;

            const requestGuid = self.crypto.randomUUID();
            const device = await IndexedDbHandler.getUserDevice();

            const requestOptions: any = Object.assign({}, dataObject.recordSource.getOptions(), {
                requestGuid: requestGuid,
                appId: appId,
                dataObjectId: dataObject.id,
                personID: userSession?.personId,
                rowCountTimeout: this.rowCountTimeout,
                shouldGenerateOfflineData: dataObject.offline.shouldGenerateOfflineData,
                truncateMode: this.truncateMode,
                deviceRef: device?.deviceRef,
                expandView: dataObject.recordSource.expandView,
                definitionProc: dataObject.recordSource.definitionProc
                // definitionProcParameters?: any;
                // TODO: add overrides for IndexedDB
            });

            if (dataObject.offline.shouldGenerateOfflineData) {
                requestOptions.originalViewName = dataObject.viewName;
                requestOptions.viewName = this.mySystemOfflineDataViewName;
                requestOptions.fields = this.mySystemOfflineDataFields;
                requestOptions.offlineDataType = dataObject.offline.objectStoreIdOverride ?? dataObject.id;
                requestOptions.offlineDataProcName = this.generateOfflineDataProcName;
                requestOptions.failOnNoRecords = this.failOnNoRecords;
            } else {
                requestOptions.viewName = dataObject.viewName;
                requestOptions.fields = dataObject.fields.fields;
            }

            // TODO: Add FilterString, WhereClause and MasterDetailString

            // ---- On Before Sync ---- //
            if (typeof this.onBeforeSync === 'function') {
                const onBeforeSyncResponse = await this.onBeforeSync(dataObject, options.memory, requestOptions);

                if (typeof onBeforeSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onBeforeSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onBeforeSyncResponse.title, onBeforeSyncResponse.body));

                    return;
                }
            }

            navigator.serviceWorker.addEventListener('message', (event: MessageEvent) => {
                const message = event.data;

                if (message.requestGuid !== requestGuid) {
                    return;
                }

                switch (message.updateType) {
                    case 'GenerateOfflineData':
                        switch (message.status) {
                            case 'Start':
                                options.stepProgress.startedGeneratingOfflineData = true;
                                break;
                            case 'Error':
                                options.stepProgress.errorsGeneratingOfflineData = true;
                                const error = message.error;
                                if(error instanceof Error){
                                    options.stepProgress.errors.push(error);
                                } else {
                                    options.stepProgress.errors.push(new Error(error.toString()));
                                }
                                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', "Error generating offline data", error.toString()));
                                break;
                            case 'Complete':
                                options.stepProgress.completedGeneratingOfflineData = true;
                                break;
                        }
                        break;
                    case 'RowCount':
                        switch (message.status) {
                            case 'Start':
                                options.stepProgress.startedRetrievingRowCount = true;
                                break;
                            case 'Error':
                                options.stepProgress.errorsRetrievingRowCount = true;

                                const error = message.error;
                                if(error instanceof Error){
                                    options.stepProgress.errors.push(error);
                                } else {
                                    options.stepProgress.errors.push(new Error(error.toString()));
                                }
                                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', "Error retrieving row count", error.toString()));

                                break;
                            case 'Complete':
                                const rowCount = message.total;

                                options.stepProgress.completedRetrievingRowCount = true;

                                if (rowCount < 0) {
                                    options.stepProgress.errorsRetrievingRowCount = true;
                                    options.stepProgress.recordsToSync = 0;
                                } else {
                                    options.stepProgress.recordsToSync = rowCount;
                                }

                                break;
                        }
                        break;
                    case 'Retrieve':
                        switch (message.status) {
                            case 'Start':
                                break;
                            case 'RecordsDownloadedAndParsed':
                                const recordsDownloaded = message.recordsDownloaded;
                                options.stepProgress.recordsStarted = recordsDownloaded;
                                break;
                            case 'RecordsStored':
                                const recordsInserted = message.recordsInserted;
                                options.stepProgress.recordsCompleted = recordsInserted;
                                break;
                            case 'Complete':
                                break;
                        }
                        break;
                    case 'FileRetrieve':
                        switch (message.status) {
                            case 'Start':
                                options.stepProgress.startedRetrievingFiles = true;
                            break;
                            case 'filesToSync':
                                const filesToSync = message.filesToSync;
                                options.stepProgress.actualFilesToSync = filesToSync;
                                break;
                            case "Error":
                                options.stepProgress.filesFailed += 1;

                                const error = message.error;
                                if(error instanceof Error){
                                    options.stepProgress.errors.push(error);
                                } else {
                                    options.stepProgress.errors.push(new Error(error.toString()));
                                }
                                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', "Error retrieving file", error.toString()));

                                break;
                            case 'filesToSyncUpdate':
                                const update = message.updatedFileCount;
                                options.stepProgress.actualFilesToSync = update 
                            break;
                            case 'FileInserted':
                                options.stepProgress.filesCompleted += 1;
                                break;
                            case 'Complete':
                                options.stepProgress.completedRetrievingFiles = true;
                            break;
                        }
                        break;
                }
            });

            await fetch('/nt/api/pwa/offline-sync', {
                method: 'POST',
                body: JSON.stringify(requestOptions),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            
            // ---- On After Sync ---- //
            if (typeof this.onAfterSync === 'function') {
                const onAfterSyncResponse = await this.onAfterSync(dataObject, options.memory, []);

                if (typeof onAfterSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onAfterSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onAfterSyncResponse.title, onAfterSyncResponse.body));
                    
                    return;
                }
            }
        } catch (error: any) {            
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
            options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', 'Try again or contact support if the issue does not get resolved'));
        }
    }
    
    async syncOnline(options: ISyncOptions<DataObjectProgress>): Promise<void> {
        try {
            // const userSession = getUserSession();

            // if (userSession?.personId !== 64800) {
            //     return;
            // }

            // TODO: Switch onBeforeSyncResponse and onAfterSyncResponse to interface or class to contain both online and offline settings
            // TODO: Switch paramaters to interface or class to contain both online and offline settings

            const dataObject: DataObject = getDataObjectById(this.dataObjectId, app.id);
            
            dataObject.enableOffline();

            // ---- Generate Offline Data ---- //
            if (dataObject.shouldEnableOffline === false) {
                throw Error('Invalid DataObject. DataObject must be flagged to use generated offline data to run online sync')
            }

            const appId = app.id;

            const requestGuid = self.crypto.randomUUID();
            const device = await IndexedDbHandler.getUserDevice();

            const requestOptions = {
                requestGuid: requestGuid,
                appId: appId,
                dataObjectId: dataObject.id,
                originalViewName: dataObject.viewName,
                viewName: this.mySystemOfflineDataViewName,
                offlineDataType: dataObject.offline.objectStoreIdOverride ?? dataObject.id,
                objectStoreIdOverride: dataObject.offline.objectStoreIdOverride,
                personID: userSession.personId,
                truncateMode: this.truncateMode,
                deviceRef: device?.deviceRef

            };

            // ---- On Before Sync ---- //
            if (typeof this.onBeforeSync === 'function') {
                const onBeforeSyncResponse = await this.onBeforeSync(dataObject, options.memory, requestOptions);

                if (typeof onBeforeSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onBeforeSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onBeforeSyncResponse.title, onBeforeSyncResponse.body));
                }
            }
            navigator.serviceWorker.addEventListener('message', (event: MessageEvent) => {
                const message = event.data;

                if (message.requestGuid !== requestGuid) {
                    return;
                }
                switch (message.updateType) {
                    case 'RowCount':
                        switch (message.status) {
                            case 'Start':
                                options.stepProgress.startedRetrievingRowCount = true;
                                break;
                            case 'Error':
                                options.stepProgress.errorsRetrievingRowCount = true;

                                const error = message.error;
                                if(error instanceof Error){
                                    options.stepProgress.errors.push(error);
                                } else {
                                    options.stepProgress.errors.push(new Error(error.toString()));
                                }
                                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', "Error retrieving row count", error.toString()));

                                break;
                            case 'Complete':
                                const rowCount = message.total;

                                options.stepProgress.completedRetrievingRowCount = true;
                                options.stepProgress.recordsToSync = rowCount;
                                break;
                        }
                        break;
                    case 'OnlineSync':
                        switch (message.status) {
                            case 'Start':
                                options.stepProgress.onlineSyncStarted = true;
                            break;
                            case 'Failed':
                                options.stepProgress.onlineSyncFailed = true;

                                const error = message.error;
                                if(error instanceof Error){
                                    options.stepProgress.errors.push(error);
                                } else {
                                    options.stepProgress.errors.push(new Error(error.toString()));
                                }
                                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', "Error syncing records.", error.toString()));

                                break;
                            case 'Complete':
                                options.stepProgress.onlineSyncCompleted = true;
                            break;
                        }
                        break;
                    case 'OnlineSyncRecords':
                        switch (message.status) {
                            case 'Start':
                                options.stepProgress.onlineSyncStarted = true;
                            break;
                            case 'RecordsToSync':
                                options.stepProgress.recordsToSync = message.recordsToSync;
                            break;
                            case 'RecordSynced':
                                options.stepProgress.recordsSynced += 1;
                            break;
                            case 'Failed':
                                const error = message.error;

                                if(error instanceof Error){
                                    options.stepProgress.errors.push(error);
                                } else {
                                    options.stepProgress.errors.push(new Error(error.toString()));
                                }
                                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', "Error syncing records.", error.toString()));

                                options.stepProgress.onlineSyncFailed = true;
                                options.stepProgress.recordsFailed += 1;
                            break;
                            case 'Complete':
                                options.stepProgress.onlineSyncCompleted = true;
                            break;
                        }
                        break;
                    case 'OnlineSyncFiles':
                        switch (message.status) {
                            case 'Start':
                                options.stepProgress.onlineFileSyncStarted = true;
                                options.stepProgress.filesToSync = message.filesToSync;
                            break;
                            case 'FileSynced':
                                options.stepProgress.filesSynced += 1;
                            break;
                            case 'FileFailed':
                                options.stepProgress.filesFailed += 1;
                            break;
                            case 'Failed':
                                options.stepProgress.onlineFileSyncFailed = true;

                                const error = message.error;
                                if (error instanceof Error) {
                                    options.stepProgress.errors.push(error);
                                } else {
                                    options.stepProgress.errors.push(new Error(error.toString()));
                                }
                                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', "Error syncing records.", error.toString()));

                                break;
                            case 'Complete':
                                options.stepProgress.onlineFileSyncCompleted = true;
                            break;
                        }
                        break;
                }
            });

            /* const response = */ await fetch('/nt/api/pwa/online-sync', {
                method: 'POST',
                body: JSON.stringify(requestOptions),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            
            // ---- On After Sync ---- //
            if (typeof this.onAfterSync === 'function') {
                const onAfterSyncResponse = await this.onAfterSync(dataObject, options.memory, new Array());

                if (typeof onAfterSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onAfterSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onAfterSyncResponse.title, onAfterSyncResponse.body));
                }
            }
        } catch (error: any) {
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
        }
    }

    async truncateData(options: ISyncOptions<DataObjectProgress>): Promise<void> {
        try {
            const dataObject: DataObject = getDataObjectById(this.dataObjectId, app.id);
            
            dataObject.enableOffline();

            // ---- Generate Offline Data ---- //
            if (dataObject.shouldEnableOffline === false) {
                throw Error('Invalid DataObject. DataObject must be flagged to use generated offline data to run online sync')
            }

            const appId = app.id;

            const requestGuid = self.crypto.randomUUID();

            const requestOptions = {
                requestGuid: requestGuid,
                appId: appId,
                dataObjectId: dataObject.id,
                originalViewName: dataObject.viewName,
                viewName: this.mySystemOfflineDataViewName,
                offlineDataType: dataObject.offline.objectStoreIdOverride ?? dataObject.id,
                objectStoreIdOverride: dataObject.offline.objectStoreIdOverride,
                personID: userSession.personId,
                truncateMode: this.truncateMode
                // TODO: add overrides for IndexedDB
            };

            // ---- On Before Sync ---- //
            if (typeof this.onBeforeSync === 'function') {
                const onBeforeSyncResponse = await this.onBeforeSync(dataObject, options.memory, requestOptions);

                if (typeof onBeforeSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onBeforeSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onBeforeSyncResponse.title, onBeforeSyncResponse.body));
                }
            }
            navigator.serviceWorker.addEventListener('message', (event: MessageEvent) => {
                const message = event.data;

                if (message.requestGuid !== requestGuid) {
                    return;
                }
                switch (message.updateType) {
                    case 'Truncate':
                        switch (message.status) {
                            case 'Start':
                                options.stepProgress.startedTruncating = true;
                                break;
                            case 'Error':
                                options.stepProgress.errorsTruncating = true;

                                const error = message.error;
                                if(error instanceof Error){
                                    options.stepProgress.errors.push(error);
                                } else {
                                    options.stepProgress.errors.push(new Error(error.toString()));
                                }
                                options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', "Error retrieving row count", error.toString()));

                                break;
                            case 'Complete':
                                options.stepProgress.completedTruncating = true;
                                break;
                        }
                        break;
                }
            });
            /* const response = */ await fetch('/nt/api/pwa/truncate', {
                method: 'POST',
                body: JSON.stringify(requestOptions),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            
            // ---- On After Sync ---- //
            if (typeof this.onAfterSync === 'function') {
                const onAfterSyncResponse = await this.onAfterSync(dataObject, options.memory, new Array());

                if (typeof onAfterSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onAfterSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onAfterSyncResponse.title, onAfterSyncResponse.body));
                }
            }

            // TODO: Implement
            await new Promise((resolve, _reject) => {
                setTimeout(resolve, 2000);
            });
            
        } catch (error: any) {
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
        }
    }
}
